import React, { useEffect, useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';

import { i18n } from 'bundles/utils';
import { WithFormControlProps, InputRef } from 'bundles/hocs/withFormControl/types';

import './styles.scss';

const MultiSelect = React.forwardRef((props: WithFormControlProps, ref: InputRef) => {
  const multiselectRef = useRef<Multiselect | null>(null);

  useEffect(() => { !props.value?.length && multiselectRef.current?.renderMultiselectContainer(); }, [props.value]); //handle outside reset event

  return (
    <Multiselect
      options={props.options}
      selectedValues={props.rest.selectedValues || props.value}
      displayValue={props.rest.displayValue || 'name'}
      closeIcon="circle"
      closeOnSelect={false}
      disable={props.disabledWithSkipValidation || props.rest.disabled}
      showArrow={true}
      customArrow={<FontAwesomeIcon icon={faAngleDown} />}
      onSelect={props.onChange}
      onRemove={props.onChange}
      className={`form-control ${props.rest.control.getFieldState(props.rest.name, props.rest.control._formState).invalid ? 'form-error' : ''}`.trim()}
      id={props.rest.id}
      placeholder={props.rest.placeholder || i18n.t('labels.select')}
      disablePreSelectedValues={props.rest.disablePreSelectedValues}
      ref={(instance: any) => {
        if (instance) {
          multiselectRef.current = instance; //instance ref for outside Multiselect reset functionality

          ref(instance.searchBox.current);
        }
      }}
    />
  );
});

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;

