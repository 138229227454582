import { URL } from 'bundles/constants';
import axios from 'bundles/apis/defaults';
import { i18n, humanizeLabel } from 'bundles/utils';
import { FilterContextAction, FilterStateInterface } from 'bundles/contexts/FilterContext/types';

export const setMultiselectValuesFor = (selectedSubTypes: string[], availableSubTypes: Record<string, never>[]) => {
  if(selectedSubTypes) return availableSubTypes.filter((e: Record<string, never>) => selectedSubTypes.includes(e.id));
};

export const setAccTypeUrl = (accType: string) => accType?.toLowerCase() === 'consumer' ? URL.ADMINS_CONSUMERS : URL.ADMINS_MERCHANTS;

export const setCsvParams = (type?: string, list?: string) => {
  const usp = new URLSearchParams(window.location.search);

  usp.append('locale', i18n.language);
  usp.append('reports_type', type || '');
  list && usp.append('list', list);

  return usp;
};

export const setSearchParamsWith = (additional_query_params: any) =>{
  return  new URLSearchParams({
    ...Object.fromEntries(new URLSearchParams(window.location.search)),
    ...Object.fromEntries(additional_query_params || new URLSearchParams())
  });
};

export const getPageData = (state: FilterStateInterface, dispatch: (arg0: FilterContextAction) => void, additionalStateProps: Record<string, any> = {}) => {
  const { currentLoader, ...restAdditionalStateProps } = additionalStateProps;

  currentLoader && dispatch({ type: 'ON_TRIGGER_LOADER', payload: { currentLoader: currentLoader } });
  return axios
    .get(state.url, { params: setSearchParamsWith(state.additional_query_params) })
    .then(response => {
      const { [state.collection_name]: collection, [state.total_collection_name]: total, ...rest } = response.data;
      dispatch({ type: 'GET_PAGE_DATA', payload: { ...rest,  collection: collection, currentLoader: '', currentBadge: undefined, total: total, ...restAdditionalStateProps } });
    });
};

export const setBadges = (state: FilterStateInterface) => {
  const arrOfKeys = Object.keys(state.query_params || {});
  return arrOfKeys.map(k => state.query_params[k] && { humanizedName: humanizeLabel(k), name: k }).filter(Boolean);
};

export const setInputFieldLength = (name: string, value: string) => {
  const field = document.getElementById(name);
  field?.classList.contains('field-input') && (field.style.width = (value || '').length * 7 + 37 + 'px');
};

export const debounce = (cb: (...args: Record<string, any>[])=> void, delay: number) => {
  let timerId: ReturnType<typeof setTimeout>;

  return (...args: Record<string, any>[]) => {
    if (timerId) clearTimeout(timerId);

    timerId = setTimeout(() => cb(...args), delay);
  };
};
