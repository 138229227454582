export const getLastSegment = (url?: string) => {
  url = url || window.location.pathname;

  return url.split('/').pop();
};

export const getUrl = () =>
  window.location.pathname.split('/').slice(2).join('/');

export const getSearchParams = (stateUrl: string) => new URLSearchParams(stateUrl.split('?')[1] || '');

